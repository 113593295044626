import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import apiClient, {
  EmailIgnoreOptionsRequestModel,
  NotificationResponseModel,
} from 'api';
import { logout } from './authenticationSlice';

const NOTIFICATIONS_PAGE_SIZE = 20;

interface NotificationState {
  isInitialDataLoaded: boolean;
  isHeaderNotificationsVisible: boolean;
  notifications: NotificationResponseModel[];
  unreadNotificationsCount: number;
  hasMore: boolean;
}

const initialState: NotificationState = {
  isInitialDataLoaded: false,
  isHeaderNotificationsVisible: false,
  notifications: [],
  unreadNotificationsCount: 0,
  hasMore: true,
};

export const getUnreadNotificationsCount = createAsyncThunk(
  'notification/getUnreadNotificationsCount',
  async () => {
    return apiClient.getUnreadNotificationsCount();
  },
);

export const getNotifications = createAsyncThunk(
  'notification/getNotifications',
  async (notificationId?: number) => {
    return apiClient.getNotifications(NOTIFICATIONS_PAGE_SIZE, notificationId);
  },
);

export const readAllNotifications = createAsyncThunk(
  'notification/readAllNotifications',
  async () => {
    return apiClient.readAllNotification();
  },
);

export const clearAllNotifications = createAsyncThunk(
  'notification/clearAllNotifications',
  async () => {
    return apiClient.deleteNotifications();
  },
);

export const removeNotification = createAsyncThunk(
  'notification/removeNotification',
  async (notificationId: number) => {
    return apiClient.deleteNotificationById(notificationId);
  },
);

export const updateEmailIgnoreSettings = createAsyncThunk(
  'notification/updateEmailIgnoreSettings',
  async (body: EmailIgnoreOptionsRequestModel) => {
    return apiClient.updateEmailIgnoreSettings(body);
  },
);

interface NotificationReadStatusRequest {
  notificationId: number;
  isNew: boolean;
}

export const setNotificationStatus = createAsyncThunk(
  'notification/setNotificationStatus',
  async ({ notificationId, isNew }: NotificationReadStatusRequest) => {
    return apiClient.setNotificationStatus(notificationId, isNew);
  },
);

const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    handleHeaderNotificationsVisible: (
      state,
      action: PayloadAction<boolean>,
    ) => ({
      ...state,
      isHeaderNotificationsVisible: action.payload,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(logout, (state) => {
      Object.assign(state, initialState);
    });
    builder.addCase(getUnreadNotificationsCount.fulfilled, (state, action) => {
      const { payload } = action;

      state.unreadNotificationsCount = payload || 0;
    });
    builder.addCase(getNotifications.fulfilled, (state, action) => {
      const { payload } = action;
      const hasMoreRows = !(payload.length < NOTIFICATIONS_PAGE_SIZE);

      state.notifications = [...state.notifications, ...payload];
      state.hasMore = hasMoreRows;
      state.isInitialDataLoaded = true;
    });
    builder.addCase(getNotifications.rejected, (state, action) => {
      state.hasMore = false;
      state.isInitialDataLoaded = true;
    });
    builder.addCase(setNotificationStatus.fulfilled, (state, action) => {
      const { meta } = action;
      const { isNew, notificationId } = meta.arg;
      const notifications = state.notifications.map((notification) => {
        return notification.id === notificationId
          ? {
              ...notification,
              isNew,
            }
          : notification;
      });

      state.notifications = notifications;
    });
    builder.addCase(removeNotification.fulfilled, (state, action) => {
      const { meta } = action;
      state.notifications = state.notifications.filter(
        ({ id }) => id !== meta.arg,
      );
    });
    builder.addCase(readAllNotifications.fulfilled, (state) => {
      const notifications = state.notifications.map((notification) => {
        return { ...notification, isNew: false };
      });

      state.notifications = notifications;
    });
    builder.addCase(clearAllNotifications.fulfilled, (state) => {
      state.notifications = [];
      state.hasMore = false;
    });
  },
});

const { reducer, actions } = notificationSlice;

export const { handleHeaderNotificationsVisible } = actions;

export default reducer;
