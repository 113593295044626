import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import apiClient, { EditOnboardingRequestModel } from 'api';
import { HomePageSteps, OnboardingPagesEnum } from 'enums/OnboardingEnums';
import { EnumHelpers } from 'utils/enumUtils';
import { logout } from 'redux/reducers/authenticationSlice';
import { getStepsEnumByPage } from 'utils/onboardingUtils';

type OnboardingState = {
  onboardingPage?: OnboardingPagesEnum;
  activeTourStep?: StepsType;
  tourProgress: StepsType[];
  isManual: boolean;
};

export type StepsType = HomePageSteps;

export const updateOnboardingStatus = createAsyncThunk(
  'onboarding/updateOnboardingStatus',
  async (body: EditOnboardingRequestModel) => {
    return apiClient.updateOnboardingStatus(body);
  },
);

const initialState: OnboardingState = {
  onboardingPage: undefined,
  activeTourStep: undefined,
  tourProgress: [],
  isManual: false,
};

const onboardingSlice = createSlice({
  name: 'onboarding',
  initialState,
  reducers: {
    startOnboarding: (
      state,
      action: PayloadAction<{
        onboardingPage: OnboardingPagesEnum;
        firstStep: StepsType;
        isManual: boolean
      }>,
    ) => {
      state.onboardingPage = action.payload.onboardingPage;
      state.activeTourStep = action.payload.firstStep;
      state.isManual = action.payload.isManual;
      state.tourProgress.push(action.payload.firstStep);
    },
    finishOnboarding: (state) => {
      Object.assign(state, initialState);
    },
    onNextStep: (state, action: PayloadAction<StepsType>) => {
      const stepsEnum = getStepsEnumByPage(state?.onboardingPage);
      const nextStep = EnumHelpers.getNextEnumValue(stepsEnum, action.payload);

      if (nextStep) {
        state.activeTourStep = nextStep;
        state.tourProgress.push(nextStep);
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logout, (state) => {
      Object.assign(state, initialState);
    });
  },
});

const { reducer, actions } = onboardingSlice;
export const { startOnboarding, finishOnboarding, onNextStep } = actions;
export default reducer;
